import { momentLocales } from '../locales/index';

export const numberNotation = (
  number: number,
  locale = 'en'
): [string, 'K' | 'sK' | 'M' | 'sM' | 'B' | 'sB' | 'D' | 'T' | 'sT', string] => {
  // Decimal
  let value = Math.abs(Number(number));
  let notation: 'K' | 'sK' | 'M' | 'sM' | 'B' | 'sB' | 'T' | 'sT' | 'D' = 'D';

  // Trillion = 12 zeros
  if (Math.abs(Number(number)) >= 1.0e12) {
    value = Math.abs(Number(number)) / 1.0e12;
    value = Math.round(value * 10) / 10;
    value === 1 ? (notation = 'sT') : (notation = 'T');
  }

  // Billion = 9 zeros
  if (Math.abs(Number(number)) < 1.0e12 && Math.abs(Number(number)) >= 1.0e9) {
    value = Math.abs(Number(number)) / 1.0e9;
    value = Math.round(value * 10) / 10;
    value === 1 ? (notation = 'sB') : (notation = 'B');
  }

  // Million = 6 zeros
  if (Math.abs(Number(number)) < 1.0e9 && Math.abs(Number(number)) >= 1.0e6) {
    value = Math.abs(Number(number)) / 1.0e6;
    value = Math.round(value * 10) / 10;
    value === 1 ? (notation = 'sM') : (notation = 'M');
  }

  // Thousand = 3 zeros
  if (Math.abs(Number(number)) < 1.0e6 && Math.abs(Number(number)) >= 1.0e3) {
    value = Math.abs(Number(number)) / 1.0e3;
    notation = 'K';
  }

  let maximumFractionDigits = 0;
  let minimumFractionDigits = 0;
  if (value < 100) {
    maximumFractionDigits = 1;
    minimumFractionDigits = 1; // Forcing to show the decimal digit
  }

  // const stringValue = new Intl.NumberFormat([locale], { maximumFractionDigits, minimumFractionDigits }).format(value);
  const stringValue = new Intl.NumberFormat(momentLocales[locale].code, {
    maximumFractionDigits,
    minimumFractionDigits
  }).format(value);

  const fullLocalized = new Intl.NumberFormat(momentLocales[locale].code, {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0
  }).format(number);

  return [stringValue, notation, fullLocalized];
};

export const byteNotation = (number: number, locale: string): [string, 'KB' | 'MB' | 'GB' | 'B'] => {
  // Decimal
  let value = Math.abs(Number(number));
  let notation: 'KB' | 'MB' | 'GB' | 'B' = 'B';

  // Billion = 9 zeros
  if (Math.abs(Number(number)) >= 1.0e9) {
    value = Math.abs(Number(number)) / 1.0e9;
    notation = 'GB';
  }

  // Million = 6 zeros
  if (Math.abs(Number(number)) < 1.0e9 && Math.abs(Number(number)) >= 1.0e6) {
    value = Math.abs(Number(number)) / 1.0e6;
    notation = 'MB';
  }

  // Thousand = 3 zeros
  if (Math.abs(Number(number)) < 1.0e6 && Math.abs(Number(number)) >= 1.0e3) {
    value = Math.abs(Number(number)) / 1.0e3;
    notation = 'KB';
  }

  let maximumFractionDigits = 0;
  let minimumFractionDigits = 0;
  if (value < 100) {
    maximumFractionDigits = 1;
    minimumFractionDigits = 1; // Forcing to show the decimal digit
  }

  const stringValue = new Intl.NumberFormat([locale], { maximumFractionDigits, minimumFractionDigits }).format(value);

  return [stringValue, notation];
};

export const replaceZeroWithOne = (value?: number | string): number | string | undefined => {
  if (Number(value) === 0) {
    return 1;
  }
  return value;
};
